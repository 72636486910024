import { extendTheme } from "@chakra-ui/react";
import "@fontsource/open-sans";
import "@fontsource/baskervville";
import "@fontsource/caveat";

const styles = {
  global: () => ({
    body: {
      quotes: "'„', '“'",
    },
    "h1, h2, h3, h4": {
      color: "gray.700",
      fontFamily: "Open Sans",
    },
    h1: {
      fontSize: "2xl",
      pb: "4",
      fontWeight: "bold",
    },
    h2: {
      fontSize: "lg",
      pb: "2",
      fontWeight: "bold",
      color: "gray.600",
    },
    h3: {
      fontSize: "lg",
    },
    "p, span, li": {
      fontFamily: "Open Sans",
      color: "gray.600",
      lineHeight: "7",
    },
    a: {
      color: "red.600",
      _hover: {
        textDecoration: "underline",
      },
    },
    "p, ul": {
      pb: "4",
    },
    ul: {
      pl: "6",
    },
    blockquote: {
      p: "4",
      backgroundColor: "gray.100",
      fontFamily: "Baskervville",
    },
    cite: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: "xs",
    },
  }),
};

const theme = extendTheme({
  fonts: {
    heading: "Baskervville",
    body: "Open Sans",
  },
  styles,
});

export default theme;
