// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-self-care-landing-js": () => import("./../../../src/pages/self-care-landing.js" /* webpackChunkName: "component---src-pages-self-care-landing-js" */),
  "component---src-pages-verlag-js": () => import("./../../../src/pages/verlag.js" /* webpackChunkName: "component---src-pages-verlag-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-reading-sample-js": () => import("./../../../src/templates/readingSample.js" /* webpackChunkName: "component---src-templates-reading-sample-js" */)
}

